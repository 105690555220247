// PRELOADER
window.onload = function () {
	$(".overlay").removeClass("overlay--preload").addClass("hidden");
}

var myskrollr;
// var bottominterval;

function sticktobottom() {
	console.log("ding");
	// myskrollr.setScrollTop( myskrollr.getMaxScrollTop() );
}

// Drag events
// - non-jqueryed becuase hammer.js seems to overide otherwise

// On drag start put save the ID, turn off hammerjs scrolling
// 'cause these interfere with one another'
function drag(e) {
	// myskrollr.setScrollTop( myskrollr.getMaxScrollTop() );
	// bottominterval = setInterval('sticktobottom', 100);
	// console.log("dong");
	e.dataTransfer.setData("text", e.target.id);
	myhammer.set({ enable: false });
}


// When drag ends turn hammerjs back on
function dragend(e) {
	myhammer.set({ enable: true });
	// clearInterval(bottominterval);
	// console.log("dang");
}

// $(function() {

	// Set draggable false for everything to prevent iOS bug
	$("img").not("#soup-can").attr("draggable","false");

	// Drop events
	$(".path-droppable")
		.on("dragover", function(e) {
			e.preventDefault();
			e.originalEvent.dataTransfer.setData("text", e.target.id);
			$(this).addClass("path-dragover");
		})
		.on("dragleave", function(e) {
			e.preventDefault();
			$(this).removeClass("path-dragover");
		})
		.on("drop", function(e) {
			myhammer.set({ enable: true });
			e.preventDefault();
			var data = e.originalEvent.dataTransfer.getData("text");
			if (data == "soup-can") {
				$(".overlay").addClass("overlay--preload").removeClass("hidden");
				window.location.href = $(this).attr("href");
			}
		});

	// List svgs in our spritesheet.
	function svg_debug(thenode){
		console.log('symbols:');
	  	$(thenode).find("symbol").each(function(){
	  		console.log(this.id);
	  		$("#sprites").prepend('<svg class="icon"><use xlink:href="#' + this.id + '" /></svg>');
	  	});
	}


	// Ajax load the SPRITESHEET, inject at the top of our document and hide it.
	// Because the sheet is an external file it'll get cached from page to page.
	$.get("img/sprites.svg", function(data) {
	  $("body").prepend("<div id='svg-inject' style='display: none'>" + new XMLSerializer().serializeToString(data.documentElement) + "</div>")
	})
	  // .success(function(){
	  // 	svg_debug('#svg-inject');
	  // })
	;





	// start SKROLLR the parallax engine
	var myskrollr = skrollr.init({
		render: function(data) {
			$('.scrollpos').html(data.curTop); // Display scroll position for debugging
		}
	});

	// skrollr - scroll to hash link. See id="story-start" in index.php
	skrollr.menu.init(myskrollr, {
		animate: true,
		easing: 'sqrt',
		// scale: 2,
		// handleLink: function(link) {
		// 	return 300;
		// },
		updateUrl: false,
	});





	// RECYCLE SPACE SCENE
	$(".steelcard").click(function(){
		$(this).toggleClass("steelcard--flip");
		if ( $(".steelcard--flip").length == 3 ) {
			$(".steelgoods--complete").removeClass("hidden");
			$(".steelgoods--default").addClass("hidden");
		} else {
			$(".steelgoods--complete").addClass("hidden");
			$(".steelgoods--default").removeClass("hidden");
		}
	});




	// RECYCLE KITCHEN SCENE
	$(".recycle-end--btn").click(function(){
		$(this).addClass("recycle-end--btnalt");
		$(".recycle-end--info").addClass("recycle-end--infodisabled");
		var x = "#" + $(this).data("info");
		$(x).removeClass("recycle-end--infodisabled");

		// console.log($(".recycle-end--btnalt").length);

		if ( $(".recycle-end--btnalt").length == ($(".recycle-end--info").length - 1) ) {
			$("#allow-trash-journey").removeClass("recycle-end--infodisabled");
		};
	});

	$("#allow-trash-journey").click(function(){
		$(this).addClass("recycle-end--infodisabled");
	});







	// Show modal when ! button is clicked.
	$(".keypt--btn").click(function(){
		// $(this).next().removeClass('hidden');

		// Copy .keypt--modal content into #modal 'cause z-indexing this shit is hard
		$('#modal').html( $(this).next().html() );
		$('#overlay').removeClass('hidden');
		$('#modal').removeClass('hidden');
	});

	// Hide modal when the modal is clicked.
	$("#modal").click(function(){
		$(this).addClass('hidden');
		$('#overlay').addClass('hidden');
	});

	// Hide modal when the overlay is clicked.
	$("#overlay").click(function(){
		$('#modal').addClass('hidden');
		$('#overlay').addClass('hidden');
	});


	$(".keypt--action-btn").click(function(){
		// console.log("click");
		$(".truck-top").toggleClass("truck-top--open");
	});




	// Mobile menu toggle
	$("#site-sm-nav--toggle, #site-sm-nav").click(function(){
		$("#site-sm-nav--list").toggleClass('hidden');
		$("#site-sm-nav").toggleClass('active');
	});


	// Scroll up or down when left/right keys are pressed.
	$("body").keydown(function(e) {
		if(e.keyCode == 37) { // left
			$('body').animate({
				scrollTop: -100 + $('body').scrollTop()
			}, 50);
		}
		else if(e.keyCode == 39) { // right
			$('body').animate({
				scrollTop: 100 + $('body').scrollTop()
			}, 50);
		}
	});



	// EXTRA INTERACTIVE SCROLLING INPUTS

	// Move horizontally (drag, swipe, flick) using Hammer.js
	myhammer = new Hammer(document.body);

	myhammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });

	var lastX = 0;

	myhammer.on("panstart", function(ev) {
		lastX = 0; // init the last delta when we start dragging
	});
	myhammer.on("panstart", function(ev) {
		// do nuthin
	});
	// move left or right
	myhammer.on("panmove", function(ev) {
		// prevent fast flicking on ios?
		// if ( Math.abs(ev.velocityX)<3 ) {
			var x = myskrollr.getScrollTop(); // get current scroll position
			var moveX = ev.deltaX; // get the delta -- remember!! this is the distance from the start of the drag event
			myskrollr.setScrollTop(x - (moveX - lastX), true ); // find the delta from when we last moved and subtract from the scrollposition
			lastX = moveX; // save the last delta
		// }
	});


	// MOUSEWHEEL
	// Move horizontally using horizontal mousewheel/magicmouse
	$('body').on('mousewheel', function(event) {
		var x = myskrollr.getScrollTop();
		myskrollr.setScrollTop(x + 2 * event.deltaX, true );
	});




// });
